import React from "react";
import {
  Title,
  Title2,
  ContentSection,
  PageContainer,
  Content,
} from "../../../styles/pages";
import { useTranslation } from "react-i18next";

export const PrivacyPolicy: React.FC<any> = (props) => {
  const { t } = useTranslation();

  return (
    <PageContainer className="info-page">
      <Title className="page-title">{t("privacy_header")}</Title>
      <Content className="info-link-content">
        <ContentSection>
          <p>{t("privacy_p1")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("privacy_title1")}</Title2>
          <p>{t("privacy_p2")}</p>
          <p>{t("privacy_p3")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("privacy_title2")}</Title2>
          <p>
            <span>
              <u>{t("privacy_underline1")}</u>
            </span>
            {t("privacy_p4")}
          </p>
          <p>{t("privacy_p5")}</p>
          <p>
            <span>
              <u>{t("privacy_underline2")}</u>
            </span>
            {t("privacy_p6")}
          </p>
          <p>{t("privacy_p7")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("privacy_title3")}</Title2>
          <p>{t("privacy_p8")}</p>
          <p>{t("privacy_p9")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("privacy_title4")}</Title2>
          <p>
            <span>
              <u>{t("privacy_underline3")}</u>
            </span>
            {t("privacy_p10")}
          </p>
          <p>
            <span>
              <u>{t("privacy_underline4")}</u>
            </span>
            {t("privacy_p11")}
          </p>
          <p>{t("privacy_p12")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("privacy_title5")}</Title2>
          <p>{t("privacy_p13")}</p>
          <p>{t("privacy_p14")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("privacy_title6")}</Title2>
          <p>
            <span>
              <u>{t("privacy_underline5")}</u>
            </span>
            {t("privacy_p15")}
          </p>
          <p>
            <span>
              <u>{t("privacy_underline6")}</u>
            </span>
            {t("privacy_p16")}
          </p>
          <p>
            <span>
              <u>{t("privacy_underline7")}</u>
            </span>
            {t("privacy_p17")}
          </p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("privacy_title7")}</Title2>
          <p>{t("privacy_p18")}</p>
          <p>{t("privacy_p19")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("privacy_title8")}</Title2>
          <p>{t("privacy_p20")}</p>
          <ul>
            <li>{t("privacy_rights1")}</li>
            <li>{t("privacy_rights2")}</li>
            <li>{t("privacy_rights3")}</li>
            <li>{t("privacy_rights4")}</li>
            <li>{t("privacy_rights5")}</li>
          </ul>
          <p>{t("privacy_p21")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("privacy_title9")}</Title2>
          <p>{t("privacy_p22")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("privacy_title10")}</Title2>
          <p>{t("privacy_p23")}</p>
        </ContentSection>
      </Content>
    </PageContainer>
  );
};
