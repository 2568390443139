import { useEffect, createContext } from "react";

export function useOutsideAlerter(buttonRef: any, menuRef: any, setOpen: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (
        buttonRef.current &&
        menuRef.current &&
        !buttonRef.current.contains(event.target) &&
        !menuRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up

      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpen, buttonRef, menuRef]);
}

export const MobileMenuContext = createContext({
  menuOpen: false,
  setMenuOpen: (isOpen: boolean) => {},
});
