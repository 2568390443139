const images = {
  equalHousing: require("./equal-housing-logo.png"),
  logoRGB: require("./peerform-rgb.png"),
  logoWhite: require("./peerform-white.png"),
  homeStockImg: require("./home.png"),
  aboutUsStockImg: require("./about_us.png"),
  contactUsStockImg: require("./contact.png"),
  newsStockImg: require("./in_the_news.png"),
  news_nerdWallet: require("./news_nerdwallet.png"),
  news_time: require("./news_time.png"),
  news_theStreet: require("./news_the_street.png"),
  news_techCrunch: require("./news_techcrunch.png"),
  news_nasdaq: require("./news_nasdaq.png"),
  news_lendAcademy: require("./news_lendacademy.png"),
  news_forbes: require("./news_forbes.png"),
  news_crowdfund: require("./news_crowdfund.png"),
  news_americanBanker: require("./news_american_banker.png"),
  news_alleyWatch: require("./news_alley_watch.png"),
};
export default images;
