import React from "react";
import { NewsContainer, NewsItem, Title } from "../../styles/pages";
import { useTranslation } from "react-i18next";
import images from "../../assets/images";

export const TheNews: React.FC = (props: any) => {
  const { t } = useTranslation();

  const newsStories = [
    {
      img: images.news_nerdWallet,
      title: t("news_title_1"),
      className: "logo-nerdwallet",
      link: "https://www.nerdwallet.com/reviews/loans/personal-loans/peerform-personal-loans",
      alt: "NerdWallet",
    },
    {
      img: images.news_forbes,
      title: t("news_title_2"),
      className: "logo-forbes",
      link: "https://www.forbes.com/sites/falgunidesai/2016/02/27/an-inside-look-at-fintech-marketplace-lenders/?sh=32864ea9ae73",
      alt: "Forbes",
    },
    {
      img: images.news_americanBanker,
      title: t("news_title_3"),
      className: "logo-americanbanker",
      link: "https://www.americanbanker.com/online-lenders-are-answer-to-unbanked-problem-1079582-1.html",
      alt: "American Banker",
    },
    {
      img: images.news_techCrunch,
      title: t("news_title_4"),
      className: "logo-techcrunch",
      link: "https://techcrunch.com/2016/01/24/the-evolving-nature-of-p2p-lending-marketplaces/",
      alt: "TechCrunch",
    },
    {
      img: images.news_theStreet,
      title: t("news_title_5"),
      className: "logo-thestreet",
      link: "https://www.thestreet.com/opinion/your-survival-guide-for-escaping-a-bear-market-13431904",
      alt: "TheStreet",
    },
    {
      img: images.news_nasdaq,
      title: t("news_title_6"),
      className: "logo-nasdaq",
      link: "https://www.nasdaq.com/articles/5-best-loans-people-poor-credit-2015-04-05",
      alt: "Nasdaq",
    },
    {
      img: images.news_lendAcademy,
      title: t("news_title_7"),
      className: "logo-lendacademy",
      link: "https://www.lendacademy.com/peerform-update/",
      alt: "Lend Academy",
    },
    {
      img: images.news_crowdfund,
      title: t("news_title_8"),
      className: "logo-crowdfund",
      link: "https://www.crowdfundinsider.com/2014/10/54000-interview-peerforms-gregg-schoenberg/",
      alt: "Crowdfund Insider",
    },
    {
      img: images.news_time,
      title: t("news_title_9"),
      className: "logo-time",
      link: "https://time.com/3482727/lending-club-ipo/",
      alt: "Time",
    },
    {
      img: images.news_lendAcademy,
      title: t("news_title_10"),
      className: "logo-lendacademy",
      link: "https://www.lendacademy.com/peerform-making-a-comeback-with-a-new-underwriting-model/",
      alt: "Lend Academy",
    },
    {
      img: images.news_theStreet,
      title: t("news_title_11"),
      className: "logo-thestreet",
      link: "https://www.thestreet.com/investing/peer-lending-lures-yield-hungry-investors-12670806",
      alt: "TheStreet",
    },
    {
      img: images.news_alleyWatch,
      title: t("news_title_12"),
      className: "logo-alleywatch",
      link: "https://www.alleywatch.com/2014/04/peerform-raises-1m-in-seed-funding-for-its-peer-to-peer-lending-platform/",
      alt: "AlleyWatch",
    },
  ];
  return (
    <main className="news-page">
      <Title className="page-title"> {t("news_header")} </Title>
      <NewsContainer className="news" data-testid="news-page">
        {newsStories.map((item, idx) => {
          return (
            <NewsItem key={`${item.className}-${idx}`}>
              <img src={item.img} className={item.className} alt={item.alt} />
              <p>{item.title}</p>
              <a
                href={item.link}
                className="view-button"
                target="_blank"
                rel="noreferrer"
                data-testid="article-link"
              >
                {t("news_viewArticle", { newsSource: item.alt })}
              </a>
            </NewsItem>
          );
        })}
      </NewsContainer>
    </main>
  );
};
