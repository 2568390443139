import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Header = styled.header`
  max-height: 193px;
  width: 100vw;
  position: relative;
`;

export const HeaderNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 193px;
  height: 100px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: var(--font-family-pragati_narrow);
`;

export const HeaderNavMenu = styled.div`
  display: flex;
  justify-content: space-around;
  width: 40%;
  @media only screen and (min-width: 551px) and (max-width: 800px) {
    width: 60%;
  }
  @media only screen and (min-width: 801px) {
    width: 50%;
  }
  @media only screen and (min-width: 1001px) {
    width: 40%;
  }
  @media only screen and (min-width: 1171px) {
    width: 30%;
  }
  @media only screen and (max-width: 550px) {
    display: none;
  }
`;

export const MobileNavMenu = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  position: absolute;
  z-index: 1000;
  font-family: var(--font-family-pragati_narrow);
  margin: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-right: 1rem;
  align-items: flex-end;
  box-shadow: 10px -2px 5px grey;
  animation: slide-down 0.25s;
  @keyframes slide-down {
    0% {
      opacity: 0;
      -webkit-transform: translateY(-20%);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }
`;
export const HeaderBanner = styled.div`
  text-align: center;
  background-color: var(--blumine);
  max-height: 108px;
  padding-bottom: 28px;
  padding-top: 28px;
  font-size: 20px;
  @media only screen and (max-width: 550px) {
    height: 100px;
    font-size: 16px;
  }
  @media only screen and (min-width: 551px) and (max-width: 1171px) {
    height: 100px;
    font-size: 18px;
  }
`;

export const NavItem = styled(NavLink)`
  margin: 10px;
  list-style: none;
  font-size: 20px;
  padding: 0;
  margin: 5px;
  letter-spacing: 0;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  &.active {
    background-color: lightgrey;
    padding: 5px 10px 5px 10px;
    margin: 0;
  }
  &.mobile {
    background-color: red;
    padding: 5px 10px 5px 10px;
    margin: 0;
  }
  @media only screen and (max-width: 550px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 551px) and (max-width: 1171px) {
    font-size: 18px;
  }
`;

export const NavLogo = styled.img`
  width: 182px;
  cursor: pointer;
  @media only screen and (max-width: 550px) {
    width: 116px;
  }
  @media only screen and (min-width: 551px) and (max-width: 1171px) {
    width: 140px;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 16rem;
  background-color: var(--eastern-blue);
  font-size: 20px;
  color: white;
  @media only screen and (max-width: 550px) {
    height: 230px;
    font-size: 16px;
  }
  @media only screen and (min-width: 551px) and (max-width: 1171px) {
    height: 230px;
    font-size: 18px;
  }
`;
export const FooterItem = styled(NavLink)`
  margin: 10px;
  list-style: none;
  padding: 0;
  margin: 5px;
  letter-spacing: 0;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const EqualHousingContainer = styled.div`
  margin: 10px;
  list-style: none;
  padding: 0;
  margin: 5px;
  letter-spacing: 0;
  cursor: inherit;
`;

export const MobileMenu = styled.div``;

//*** Default Props *** //
FooterItem.defaultProps = {
  onClick: () => {
    document.body.scrollTo({
      top: 0,
      left: 0,
    });
  },
};
