import { Switch, Route, Redirect } from "react-router-dom";
import {
  Home,
  AboutUs,
  ContactUs,
  TheNews,
  Accessibility,
  CCPAStatement,
  Terms,
  PrivacyPolicy,
} from "./components/pages";
import { PAGE_URLS } from "./constants";

export const Pages = (props: any) => {
  return (
    <Switch>
      <Route exact path={PAGE_URLS.HOME} component={Home} />
      <Route path={PAGE_URLS.ABOUT_US} component={AboutUs} />
      <Route path={PAGE_URLS.IN_THE_NEWS} component={TheNews} />
      <Route path={PAGE_URLS.CONTACT_US} component={ContactUs} />
      <Route
        path={PAGE_URLS.ACCESSIBILITY_STATEMENT}
        component={Accessibility}
      />
      <Route path={PAGE_URLS.CCPA_STATEMENT} component={CCPAStatement} />
      <Route path={PAGE_URLS.PRIVACY_POLICY} component={PrivacyPolicy} />
      <Route path={PAGE_URLS.TERMS_OF_USE} component={Terms} />
      <Redirect to="/" />
    </Switch>
  );
};
