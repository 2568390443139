import React from "react";
import { PageContainer, ImageContainer } from "../../styles/pages";
import { Overlay, OverLayText } from "../../styles/overlay";
import { useTranslation } from "react-i18next";

export const ContactUs: React.FC = (props: any) => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <ImageContainer className="image-container">
        <div className="stock-img-container-contact">
          <img
            src="contact.png"
            className="home-stock-img"
            alt="Woman talking on headset"
          />
        </div>
        <Overlay page="contact">
          <OverLayText data-testid="contact-overlay">
            {t("contactUs_overlay")}
            <span>support@peerform.com</span>
          </OverLayText>
        </Overlay>
      </ImageContainer>
    </PageContainer>
  );
};
