import React from "react";
import { PageContainer, ImageContainer } from "../../styles/pages";
import { Overlay, OverLayText } from "../../styles/overlay";
import { Content } from "../../styles/pages";
import { useTranslation } from "react-i18next";

export const AboutUs: React.FC = (props: any) => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <ImageContainer className="image-container">
        <div className="stock-img-container-about">
          <img
            src="about_us.png"
            className="home-stock-img"
            alt="Man at computer"
          />
        </div>
        <Overlay className="contact-us" page="about">
          <OverLayText>{t("aboutUs_overlay")}</OverLayText>
        </Overlay>
      </ImageContainer>
      <Content>
        <div className="content-text-about-us" data-testid="about-content">
          <p>{t("aboutUs_p1")}</p>
          <p>{t("aboutUs_p2")}</p>
          <p>{t("aboutUs_p3")}</p>
          <p>{t("aboutUs_p4")}</p>
          <p>{t("aboutUs_p5")}</p>
        </div>
      </Content>
    </PageContainer>
  );
};
