import React from "react";
import { NavItem } from "../../styles/template";
import { PAGE_URLS } from "../../constants";
import { NavProps } from "../../types";

export const NavItems: React.FC<NavProps> = (props) => {
  const { setMenuOpen } = props;

  return (
    <>
      <NavItem
        to={PAGE_URLS.ABOUT_US}
        className={(isActive) => (isActive ? "active" : "")}
        onClick={() => setMenuOpen(false)}
      >
        ABOUT US
      </NavItem>
      <NavItem
        to={PAGE_URLS.IN_THE_NEWS}
        className={(isActive) => (isActive ? "active" : "")}
        onClick={() => setMenuOpen(false)}
      >
        IN THE NEWS
      </NavItem>
      <NavItem
        to={PAGE_URLS.CONTACT_US}
        className={(isActive) => (isActive ? "active" : "")}
        onClick={() => setMenuOpen(false)}
      >
        CONTACT
      </NavItem>
    </>
  );
};
