import React from "react";
import {
  Title,
  Title2,
  ContentSection,
  PageContainer,
  Content,
} from "../../../styles/pages";
import { useTranslation } from "react-i18next";
import { PAGE_URLS } from "../../../constants";

export const Terms: React.FC<any> = (props) => {
  const { t } = useTranslation();

  return (
    <PageContainer className="info-page terms">
      <Title className="page-title">{t("terms_header")}</Title>
      <Content className="info-link-content">
        <ContentSection>
          <p>{t("terms_p0")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title1")}</Title2>
          <p>{t("terms_p1")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title2")}</Title2>
          <p>{t("terms_p2")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title3")}</Title2>
          <p>{t("terms_p3")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title4")}</Title2>
          <p>{t("terms_p4")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title5")}</Title2>
          <p>{t("terms_p5")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title6")}</Title2>
          <p>{t("terms_p6")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title7")}</Title2>
          <p>{t("terms_p7")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title8")}</Title2>
          <p>{t("terms_p8")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title9")}</Title2>
          <p>{t("terms_p9")}</p>
          <p>{t("terms_p10")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title10")}</Title2>
          <p>{t("terms_p11")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title11")}</Title2>
          <p>{t("terms_p12")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title12")}</Title2>
          <p>{t("terms_p13")}</p>
          <p>{t("terms_p14")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title13")}</Title2>
          <p>{t("terms_p15")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title14")}</Title2>
          <p>{t("terms_p16")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title15")}</Title2>
          <p>{t("terms_p17")}</p>
          <p>{t("terms_p18")}</p>
          <p>{t("terms_p19")}</p>
          <p>{t("terms_p20")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title16")}</Title2>
          <p>
            {t("terms_p21")}
            <a href={PAGE_URLS.PRIVACY_POLICY}>{t("privacy_header")}</a>
            {t("terms_p21b")}
          </p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title17")}</Title2>
          <p>{t("terms_p22")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title18")}</Title2>
          <p>{t("terms_p23")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title19")}</Title2>
          <p>
            {t("terms_p24")}
            <a href={PAGE_URLS.PRIVACY_POLICY}>{t("privacy_header")}</a>
            {t("terms_p24b")}
          </p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("terms_title20")}</Title2>
          <p>{t("terms_p25")}</p>
        </ContentSection>
      </Content>
    </PageContainer>
  );
};
