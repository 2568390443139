import React, { useContext, useRef, useEffect } from "react";
import {
  Header,
  Footer,
  HeaderNav,
  HeaderNavMenu,
  HeaderBanner,
  NavLogo,
  FooterItem,
  MobileNavMenu,
  EqualHousingContainer,
} from "../../styles/template";
import { useTranslation } from "react-i18next";
import { GiHamburgerMenu } from "react-icons/gi";
import images from "../../assets/images";
import { PAGE_URLS } from "../../constants";
import { Link } from "react-router-dom";
import "../../styles/template.scss";
import { NavItems } from "./NavItems";
import { MobileMenuContext, useOutsideAlerter } from "../../utils/useMenuRef";
import { useWindowSize } from "../../utils/useWindowSize";

export const PageTemplate: React.FC<any> = (props) => {
  const { children } = props;
  const { t } = useTranslation();
  const { menuOpen, setMenuOpen } = useContext(MobileMenuContext);
  const mobileMenuRef = useRef(null);
  const hamburgerRef = useRef(null);
  const windowWidth = useWindowSize();
  useOutsideAlerter(hamburgerRef, mobileMenuRef, setMenuOpen);

  useEffect(() => {
    if (windowWidth > 550) {
      setMenuOpen(false);
    }
  }, [windowWidth, setMenuOpen]);

  return (
    <div className="main-container">
      <Header>
        <HeaderNav data-testid="nav-bar">
          <Link to={PAGE_URLS.HOME}>
            <NavLogo src={images.logoRGB} alt="Peerform Logo" />
          </Link>
          {windowWidth <= 550 ? (
            <div className="dropdown-icon" ref={hamburgerRef}>
              <GiHamburgerMenu
                size={24}
                data-testid="mobile-nav-menu"
                onClick={() => setMenuOpen(!menuOpen)}
              />
            </div>
          ) : (
            <HeaderNavMenu data-testid="nav-menu">
              <NavItems setMenuOpen={setMenuOpen} />
            </HeaderNavMenu>
          )}
        </HeaderNav>
        {menuOpen && (
          <MobileNavMenu ref={mobileMenuRef}>
            <NavItems setMenuOpen={setMenuOpen} />
          </MobileNavMenu>
        )}
        <HeaderBanner className="header-banner" data-testid="header-banner">
          {t("no_applications_1")}
          <span className="banner-text">
            {t("no_applications_2")}
            <span className="span1rasa-bold-white-20px">
              support@peerform.com
            </span>
            <span className="rasa-bold-white-20px">.</span>
          </span>
        </HeaderBanner>
      </Header>
      {children}
      <FooterTemplate />
    </div>
  );
};

const FooterTemplate = () => {
  return (
    <Footer>
      <NavLogo src={images.logoWhite} alt="Peerform Logo" />
      <div className="footer-links" data-testid="footer-menu">
        <EqualHousingContainer className="equal-housing-container">
          <img
            src={images.equalHousing}
            className="equal-housing-logo"
            alt="Equal Housing Lender Logo"
          />
          <div>Equal Housing Lender</div>
        </EqualHousingContainer>
        <div>|</div>
        <FooterItem to={PAGE_URLS.ACCESSIBILITY_STATEMENT}>
          Accessibility Statement
        </FooterItem>
        <div>|</div>
        <FooterItem to={PAGE_URLS.CCPA_STATEMENT}>CCPA Statement</FooterItem>
        <div>|</div>
        <FooterItem to={PAGE_URLS.PRIVACY_POLICY}>Privacy Policy</FooterItem>
        <div>|</div>
        <FooterItem to={PAGE_URLS.TERMS_OF_USE}>Terms Of Use</FooterItem>
        <div>|</div>
        <FooterItem to={PAGE_URLS.CONTACT_US}>Contact Us</FooterItem>
      </div>
      <p className="signature">
        <span>© 2022 Peerform All Rights Reserved.</span>
      </p>
    </Footer>
  );
};
