import { useState } from "react";
import { PageTemplate } from "./components/shared/PageTemplate";
import { Pages } from "./routes";
import { BrowserRouter as Router } from "react-router-dom";
import { MobileMenuContext } from "./utils/useMenuRef";
import "./index.scss";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div>
      <MobileMenuContext.Provider value={{ menuOpen, setMenuOpen }}>
        <Router>
          <PageTemplate>
            <Pages />
          </PageTemplate>
        </Router>
      </MobileMenuContext.Provider>
    </div>
  );
}

export default App;
