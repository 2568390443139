import React from "react";
import {
  Title,
  Title2,
  ContentSection,
  PageContainer,
  Content,
} from "../../../styles/pages";
import { useTranslation } from "react-i18next";
export const Accessibility: React.FC<any> = (props) => {
  const { t } = useTranslation();

  return (
    <PageContainer className="info-page">
      <Title className="page-title">{t("accessibility_header")}</Title>
      <Content className="info-link-content">
        <ContentSection>
          <Title2>{t("accessibility_title1")}</Title2>
          <p>{t("accessibility_p1")}</p>
          <p>{t("accessibility_p2")}</p>
          <p>{t("accessibility_p3")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("accessibility_title2")}</Title2>
          <p>{t("accessibility_p4")}</p>
          <p>{t("accessibility_p5")}</p>
          <p>{t("accessibility_p6")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("accessibility_title3")}</Title2>
          <p>{t("accessibility_p7")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("accessibility_title4")}</Title2>
          <p>{t("accessibility_p8")}</p>
        </ContentSection>
      </Content>
    </PageContainer>
  );
};
