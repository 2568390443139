export const PAGE_URLS = {
  HOME: "/",
  ABOUT_US: "/about-us",
  IN_THE_NEWS: "/in-the-news",
  CONTACT_US: "/contact-us",
  ACCESSIBILITY_STATEMENT: "/accessibility-statement",
  CCPA_STATEMENT: "/ccpa-statement",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_USE: "/terms-of-use",
};
