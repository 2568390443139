import React from "react";
import {
  Title,
  Title2,
  Title3,
  ContentSection,
  PageContainer,
  Content,
  Table,
  TableData,
  TableRow,
  TableHeader,
} from "../../../styles/pages";
import { useTranslation } from "react-i18next";
import { PAGE_URLS } from "../../../constants";

export const CCPAStatement: React.FC<any> = (props) => {
  const { t } = useTranslation();

  return (
    <PageContainer className="info-page">
      <Title className="page-title">{t("ccpa_header")}</Title>
      <Content className="info-link-content">
        <ContentSection>
          <p>
            <span>{t("ccpa_p1")}</span>
            <a href={PAGE_URLS.PRIVACY_POLICY} data-testid="privacy-link">
              {t("privacy_header")}
            </a>
            <span>{t("ccpa_p1b")}</span>
          </p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("ccpa_title1")}</Title2>
          <p>{t("ccpa_p2")}</p>
          <ul>
            <li>{t("ccpa_definition1")}</li>
            <li>{t("ccpa_definition2")}</li>
            <li>
              {t("ccpa_definition3")}
              <ul>
                <li>{t("ccpa_definition3_sub1")}</li>
                <li>{t("ccpa_definition3_sub2")}</li>
              </ul>
            </li>
          </ul>
          <p>{t("ccpa_p3")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("ccpa_title2")}</Title2>
          <p>{t("ccpa_p4")}</p>
          <Table data-testid="collected-information-table">
            <tbody>
              <TableRow>
                <TableHeader>{t("ccpa_table_header1")}</TableHeader>
                <TableHeader>{t("ccpa_table_header2")}</TableHeader>
                <TableHeader>{t("ccpa_table_header3")}</TableHeader>
              </TableRow>
              <TableRow>
                <TableData>{t("ccpa_table_col1_row1")}</TableData>
                <TableData>{t("ccpa_table_col2_row1")}</TableData>
                <TableData>{""}</TableData>
              </TableRow>
              <TableRow>
                <TableData>{t("ccpa_table_col1_row2")}</TableData>
                <TableData>{t("ccpa_table_col2_row2")}</TableData>
                <TableData>{""}</TableData>
              </TableRow>
              <TableRow>
                <TableData>{t("ccpa_table_col1_row3")}</TableData>
                <TableData>{t("ccpa_table_col2_row3")}</TableData>
                <TableData>{""}</TableData>
              </TableRow>
              <TableRow>
                <TableData>{t("ccpa_table_col1_row4")}</TableData>
                <TableData>{t("ccpa_table_col2_row4")}</TableData>
                <TableData>{""}</TableData>
              </TableRow>
              <TableRow>
                <TableData>{t("ccpa_table_col1_row5")}</TableData>
                <TableData>{t("ccpa_table_col2_row5")}</TableData>
                <TableData>{""}</TableData>
              </TableRow>
              <TableRow>
                <TableData>{t("ccpa_table_col1_row6")}</TableData>
                <TableData>{t("ccpa_table_col2_row6")}</TableData>
                <TableData>{""}</TableData>
              </TableRow>
              <TableRow>
                <TableData>{t("ccpa_table_col1_row7")}</TableData>
                <TableData>{t("ccpa_table_col2_row7")}</TableData>
                <TableData>{""}</TableData>
              </TableRow>
              <TableRow>
                <TableData>{t("ccpa_table_col1_row8")}</TableData>
                <TableData>{t("ccpa_table_col2_row8")}</TableData>
                <TableData>{""}</TableData>
              </TableRow>
              <TableRow>
                <TableData>{t("ccpa_table_col1_row9")}</TableData>
                <TableData>{t("ccpa_table_col2_row9")}</TableData>
                <TableData>{""}</TableData>
              </TableRow>
            </tbody>
          </Table>
        </ContentSection>
        <ContentSection>
          <Title2>{t("ccpa_title3")}</Title2>
          <p>{t("ccpa_p5")}</p>
          <ul>
            <li>{t("ccpa_sources1")}</li>
            <li>{t("ccpa_sources2")}</li>
            <li>{t("ccpa_sources3")}</li>
            <li>{t("ccpa_sources4")}</li>
          </ul>
        </ContentSection>
        <ContentSection>
          <Title2>{t("ccpa_title4")}</Title2>
          <p>{t("ccpa_p6")}</p>
          <ul>
            <li>{t("ccpa_useOfInfo1")}</li>
            <li>{t("ccpa_useOfInfo2")}</li>
            <li>{t("ccpa_useOfInfo3")}</li>
            <li>{t("ccpa_useOfInfo4")}</li>
            <li>{t("ccpa_useOfInfo5")}</li>
            <li>{t("ccpa_useOfInfo6")}</li>
            <li>{t("ccpa_useOfInfo7")}</li>
            <li>{t("ccpa_useOfInfo8")}</li>
            <li>{t("ccpa_useOfInfo9")}</li>
          </ul>
          <p>{t("ccpa_p7")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("ccpa_title5")}</Title2>
          <p>{t("ccpa_p8")}</p>
          <p>{t("ccpa_p9")}</p>
          <p>{t("ccpa_sharingInfo_cat1")}</p>
          <p>{t("ccpa_sharingInfo_cat2")}</p>
          <p>{t("ccpa_sharingInfo_cat3")}</p>
          <p>{t("ccpa_sharingInfo_cat4")}</p>
          <p>{t("ccpa_p10")}</p>
          <ul>
            <li>{t("ccpa_sharingInfo1")}</li>
            <li>{t("ccpa_sharingInfo2")}</li>
            <li>{t("ccpa_sharingInfo3")}</li>
            <li>{t("ccpa_sharingInfo4")}</li>
          </ul>
        </ContentSection>
        <ContentSection>
          <Title2>{t("ccpa_title6")}</Title2>
          <p>{t("ccpa_p11")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("ccpa_title7")}</Title2>
          <p>{t("ccpa_p12")}</p>
        </ContentSection>
        <ContentSection>
          <Title3>{t("ccpa_rights1")}</Title3>
          <p>{t("ccpa_p13")}</p>
          <ul>
            <li>{t("ccpa_yourRights1")}</li>
            <li>{t("ccpa_yourRights2")}</li>
            <li>{t("ccpa_yourRights3")}</li>
            <li>{t("ccpa_yourRights4")}</li>
            <li>{t("ccpa_yourRights5")}</li>
            <li>
              {t("ccpa_yourRights6")}
              <ul>
                <li>{t("ccpa_yourRights6_sub1")}</li>
                <li>{t("ccpa_yourRights6_sub2")}</li>
              </ul>
            </li>
          </ul>
        </ContentSection>
        <ContentSection>
          <Title3>{t("ccpa_rights2")}</Title3>
          <p>{t("ccpa_p14")}</p>
          <p>{t("ccpa_p15")}</p>
          <ul className="ccpa-rights2-list">
            <li>{t("ccpa_deletion1")}</li>
            <li>{t("ccpa_deletion2")}</li>
            <li>{t("ccpa_deletion3")}</li>
            <li>{t("ccpa_deletion4")}</li>
            <li>{t("ccpa_deletion5")}</li>
            <li>{t("ccpa_deletion6")}</li>
            <li>{t("ccpa_deletion7")}</li>
            <li>{t("ccpa_deletion8")}</li>
            <li>{t("ccpa_deletion9")}</li>
          </ul>
        </ContentSection>
        <ContentSection>
          <Title3>{t("ccpa_rights3")}</Title3>
          <p>{t("ccpa_p16")}</p>
          <ul>
            <li>{t("ccpa_howToRequest1")}</li>
            <li>{t("ccpa_howToRequest2")}</li>
          </ul>
          <p>{t("ccpa_p17")}</p>
          <p>{t("ccpa_p18")}</p>
          <ul>
            <li>{t("ccpa_howToRequest3")}</li>
            <li>{t("ccpa_howToRequest4")}</li>
          </ul>
          <p>{t("ccpa_p19")}</p>
        </ContentSection>
        <ContentSection>
          <Title3>{t("ccpa_rights4")}</Title3>
          <p>{t("ccpa_p20")}</p>
          <p>{t("ccpa_p21")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("ccpa_title8")}</Title2>
          <p>{t("ccpa_p22")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("ccpa_title9")}</Title2>
          <p>{t("ccpa_p23")}</p>
        </ContentSection>
        <ContentSection>
          <Title2>{t("ccpa_title10")}</Title2>
          <p>{t("ccpa_p24")}</p>
        </ContentSection>
      </Content>
    </PageContainer>
  );
};
