import styled from "styled-components";

export const PageContainer = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ImageContainer = styled.div`
  position: relative;
  z-index: 1;
  @media only screen and (max-width: 551px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const Content = styled.div`
  font-family: var(--font-family-rasa);
  margin-top: 1rem;
`;

// IN THE NEWS
export const NewsContainer = styled.ul`
  display: flex;
  flex-direction: column;
`;

export const NewsItem = styled.li`
  border-bottom: 1px solid lightgrey;
  font-family: var(--font-family-rasa);
  font-size: 18px;
  display: flex;
  flex-direction: column;
  width: 90%;
  & img {
    margin-top: 1rem;
  }
`;

export const Title = styled.h1`
  font-size: 48px;
  font-family: var(--font-family-pragati_narrow);
  font-weight: 400;
  font-style: bold;
  @media screen and (max-width: 550px) {
    font-size: 24px;
  }
  @media screen and (min-width: 551px) and (max-width: 850px) {
    font-size: 36px;
    width: 100%;
  }
`;

export const Title2 = styled.h2`
  text-align: left;
  font-size: 24px;
  font-family: var(--font-family-pragati_narrow);
  font-weight: 700;
  @media screen and (max-width: 550px) {
    font-size: 16px;
  }
  @media screen and (min-width: 551px) and (max-width: 850px) {
    font-size: 24px;
  }
`;

export const Title3 = styled.h3`
  text-align: left;
  font-size: 20px;
  font-family: var(--font-family-pragati_narrow);
  font-weight: 400;
  text-decoration: underline;
  @media screen and (max-width: 550px) {
    font-size: 16px;
  }
  @media screen and (min-width: 551px) and (max-width: 850px) {
    font-size: 18px;
  }
`;
export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  font-family: var(--font-family-rasa);
  font-size: 18px;
  height: min-content;
  & > * {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
  }
  @media screen and (max-width: 550px) {
    font-size: 16px;
  }
  @media screen and (min-width: 551px) and (max-width: 1171px) {
    font-size: 18px;
  }
`;

export const Table = styled.table`
  border: 2px solid black;
  border-collapse: collapse;
`;

export const TableData = styled.td`
  border: 2px solid black;
  height: 80px !important;
  padding: 10px;
`;

export const TableHeader = styled.th`
  border: 2px solid black;
`;

export const TableRow = styled.tr`
  border: 2px solid white;
  fill: black;
`;
