import styled from "styled-components";
import { Pages, OverlayProps } from "../types";

export const Overlay = styled.div`
  display: table;
  background-color: var(--powder-blue);
  border-radius: 2px;
  position: absolute;
  ${(props: OverlayProps) => getOverlayPosition(props.page)}
  @media only screen and (max-width: 550px) {
    width: 100vw;
    height: 70px;
    position: initial;
  }
  @media only screen and (min-width: 551px) and (max-width: 1171px) {
    width: 300px;
    height: 120px;
  }
`;

export const OverLayText = styled.h1`
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.035em;
  text-align: left;
  vertical-align: middle;
  display: table-cell;
  padding-left: 40px;
  padding-right: 70px;
  line-height: 111.3%;
  font-family: var(--font-family-pragati_narrow);
  @media only screen and (max-width: 550px) {
    font-size: 18px;
    text-align: center;
  }
  @media only screen and (min-width: 551px) and (max-width: 1171px) {
    font-size: 21px;
  }
`;

function getOverlayPosition(view: Pages): any {
  switch (view) {
    case "home":
      return `
        width: 450px;
        height: 200px;
        bottom: 50px;
        right: 0;
      `;
    case "about":
      return `
        width: 500px;
        height: 299px;
        bottom: 50px;
        right: 0;
      `;
    case "contact":
      return `
        width: 400px;
        height: 200px;
        bottom: 50px;
        left: 0;
      `;
  }
}

Overlay.defaultProps = {
  page: "home",
};
