import React from "react";
import { PageContainer, ImageContainer } from "../../styles/pages";
import { Overlay, OverLayText } from "../../styles/overlay";
import { Content } from "../../styles/pages";
import { useTranslation } from "react-i18next";

export const Home: React.FC = (props: any) => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <ImageContainer className="image-container">
        <div className="stock-img-container-home">
          <img
            src="home.png"
            className="home-stock-img"
            alt="Woman at computer"
          />
        </div>
        <Overlay page="home">
          <OverLayText>{t("home_overlay")}</OverLayText>
        </Overlay>
      </ImageContainer>
      <Content className="content-text">
        <p className="content-text-home">{t("home_content")}</p>
      </Content>
    </PageContainer>
  );
};
