import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
import resources from "./translations.json";

// import typescript keys from translations.json
declare module "react-i18next" {
  // and extend them!
  interface Resources {
    en: typeof resources.en.translation;
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
